$(function () {
  $(".filter-select-wrapper > select").on('change', function () {
    console.log("working")
    var selectValue = $(this)
      .val();
    if (selectValue == "vitality-magazine") {
      window.location.href = "/vitality-magazine/";
    } else {
      window.location.href = "/magazine-category/" + selectValue;
    }
  });
});