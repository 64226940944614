import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);

function buildChart(clickedID) {
  //var chart = $('.single-chart-wrapper canvas');
  //var chartID = $(chart).data('id');
  //var fullChartID = '#single-chart-' + chartID;
  var fullChartID = clickedID;
  var chartType = $(fullChartID).data('charttype');
  var chartLabels = $(fullChartID).data('labels');
  var chartNumbers = $(fullChartID).data('numbers');
  var chartBackgrounds = $(fullChartID).data('backgrounds');
  var chartBorders = $(fullChartID).data('borders');
  var chartLabelStyles = {};
  var scalesStyles = {};
  if (chartType == "pie") {
    chartLabelStyles = {
      color: '#000000',
      font: {
        weight: 'normal',
        size: '14',
      },
      textAlign: 'center',
      anchor: 'end',
      align: 'end',
      offset: 10,
      padding: 0,
      display: function (context) {
        var dataset = context.dataset;
        var value = dataset.data[context.dataIndex];
        return value;
      },
      formatter: function (value, context) {
        return value + '%' + '\n' + context.chart.data.labels[context.dataIndex];
      },
    };
    scalesStyles = {
      display: false
    };

  } else {
    chartLabelStyles = {
      color: '#000000',
      font: {
        weight: 'normal',
        size: '14',
      },
      textAlign: 'center',
      anchor: 'end',
      align: 'end',
      offset: 10,
      padding: 0,
      display: function (context) {
        var dataset = context.dataset;
        var value = dataset.data[context.dataIndex];
        return value;
      },
      formatter: function (value, context) {
        return value;
      },
    };
    scalesStyles = {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    };
  }
  var ctx = $(fullChartID);
  window['myChart'] = new Chart(ctx, {
    type: chartType,
    data: {
      labels: chartLabels,
      datasets: [{
        data: chartNumbers,
        backgroundColor: chartBackgrounds,
        borderColor: chartBorders,
        borderWidth: 1
      }]
    },
    options: {
      scales: scalesStyles,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: chartLabelStyles
      },
      // Core options
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 16 / 9,
      layout: {
        padding: 50
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      },
    }
  });
}

$(function () {
  window.myChart = null;
  $('.chart-accordion-wrapper .accordion').on('shown.bs.collapse', function (e) {
    var clicked = $('#' + e.target.id + ' canvas').attr('id');
    var idString = '#' + clicked;
    if (window.myChart == null) {
      buildChart(idString);
    } else {
      window.myChart.destroy();
      window.myChart = null;
      buildChart(idString);
    }
  })

  $('.chart-accordion-wrapper .accordion').on('hide.bs.collapse', function () {
    window.myChart.destroy();
    window.myChart = null;
  })
});