$(document).ready(function () {
  console.log("Theme scripts working")
  $(".search-form-wrapper span").hover(function () {
    $(this).toggleClass("over")
  })
  $(".search-form-wrapper span").click(function () {
    $(this).closest(".search-form-wrapper").toggleClass("active")
    $(this).siblings("input").focus()
  })

  $(".hamburger").on("click", function () {
    $(this).toggleClass("is-active")
    $(".mobile-menu-wrapper").toggleClass("active-menu")
  })

  $(".mobile-menu-wrapper .menu-item-has-children a").on("click", function (e) {
    if (!$(this).parent().parent().parent().hasClass("sub-menu")) {
      if (!$(this).parent().parent().hasClass("menu-open")) {
        e.preventDefault()
        $(this).parent().parent().toggleClass("menu-open")
      }
    }
  })

  $(".mobile-menu-wrapper .menu-item-has-children .accordion-icon-wrapper").on(
    "click",
    function () {
      var grandParent = $(this).parent().parent()
      if (grandParent.hasClass("menu-open")) {
        $(grandParent).removeClass("menu-open")
      } else {
        $(grandParent).addClass("menu-open")
      }
    }
  )

  // News Filter Option
  if ($(".news-repeater-wrapper").length) {
    $("#area-of-interest").select2({
      allowClear: true,
      placeholder: {
        text: "Focus Area",
      },
      multiple: true,
      closeOnSelect: false,
    })

    $("#content-type").select2({
      allowClear: true,
      placeholder: {
        text: "Content Type",
      },
      multiple: true,
      closeOnSelect: false,
    })

    var observerFilter = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach(function (node) {
            // Check if the added node has the class 'select2-container--open'
            if (
              node.classList &&
              node.classList.contains("select2-container--open")
            ) {
              // console.log("hello finally")
              // updateText()
            }
          })
        }
      })
    })

    // Configuration of the observer
    var config = { attributes: false, childList: true, subtree: true }

    // Pass in the target node and the observer options
    observerFilter.observe(document.body, config)

    $("body").on("mouseout", ".select2-results__option", function () {
      $(this)
        .siblings("li")
        .addBack()
        .removeClass("select2-results__option--highlighted")
    })

    // $(".select-wrapper.unselected").on("click", function () {
    //   if ($(this).hasClass("unselected")) {
    //     console.log("test")
    //     $(this).removeClass("unselected") // Your code here
    //   }
    // })
  }

  //News Repeater Submit Function
  if ($(".news-repeater-wrapper").length) {
    $(".news-repeater-submit-button").click(function (e) {
      e.preventDefault()
      let queryParams = []

      let contentType = $(this)
        .siblings(".filter-group")
        .find("#content-type option:selected")
        .map(function () {
          //Loop through options and find selected ones
          return $(this).data("category")
        })
        .get()
      let areaOfInterest = $(this)
        .siblings(".filter-group")
        .find("#area-of-interest option:selected")
        .map(function () {
          //Loop through options and find selected ones
          return $(this).data("category")
        })
        .get()

      if (contentType.length > 0) {
        queryParams.push("post_types=" + encodeURIComponent(contentType))
      }
      if (areaOfInterest.length > 0) {
        queryParams.push("terms=" + encodeURIComponent(areaOfInterest))
      }

      var queryString =
        queryParams.length > 0 ? "?" + queryParams.join("&") : ""

      window.location.href = "/news/" + queryString
    })

    $(".card-tags a.tag").click(function (e) {
      e.preventDefault()
      let queryParams = []

      let catData = $(this).data("category")
      // console.log(catData)
      if (catData.length > 0) {
        queryParams.push("terms=" + encodeURIComponent(catData))
      }

      var queryString =
        queryParams.length > 0 ? "?" + queryParams.join("&") : ""

      window.location.href = "/news/" + queryString
    })
  }


  // Tobacco Vaping Landing Page

  // Function to remove the grayscale filter
  function removeGrayscale() {
      const header = document.querySelector('.header-with-dark-text-wrapper');

      // Ensure that the header is found before applying changes
      if (header) {
          header.classList.add('active');
      } else {
          console.error("The header element with the 'header' class was not found.");
      }
  }

  // Use setTimeout to apply changes after a delay
  setTimeout(removeGrayscale, 1000);





  // Intersection Observer options
  var options = {
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.5 // Trigger when at least 50% of the element is visible
  };

  // Function to handle visibility changes
  function handleIntersectionReveal(entries, observerReveal) {
      entries.forEach(function (entry, index) {
          if (entry.isIntersecting) {
              // Delay each reveal by an additional 500ms (0.5 seconds) per element
              setTimeout(function () {
                  entry.target.classList.add('revealed');
                  // Stop observing this element as it's already revealed
                  observerReveal.unobserve(entry.target);
              }, index * 200); // Stagger delay
          }
      });
  }

  // Initialize the IntersectionObserver after a delay
  function initializeObserver() {
      var observerReveal = new IntersectionObserver(handleIntersectionReveal, options);
      // Observe all elements with the "reveal" class
      var revealElements = document.querySelectorAll('.reveal');
      revealElements.forEach(function (element) {
          observerReveal.observe(element);
      });
  }

  // Delay for 1 second (1000 milliseconds)
  setTimeout(initializeObserver, 1500);







// Function to count up from 0 to the target value
function countUp(element, target, duration = 1500) {
  let current = 0;
  const hasDecimal = target % 1 !== 0; // Checks if the number is a decimal

  const interval = 30; // Milliseconds between increments
  const increments = Math.ceil(duration / interval);
  const increment = target / increments;

  const counter = setInterval(() => {
      current += increment;
      if (current >= target) {
          current = target;
          clearInterval(counter);
      }
      element.textContent = hasDecimal ? current.toFixed(1) : Math.round(current).toLocaleString();
  }, interval);
}

// Callback for the IntersectionObserver
function handleIntersection(entries, observer) {
  entries.forEach((entry) => {
      if (entry.isIntersecting && !entry.target.closest('.revealed-content')) {
          // Count up only if the element is not a child of 'revealed-content'
          setTimeout(() => {
              const target = parseFloat(entry.target.dataset.target);
              countUp(entry.target, target, 1500); // Count up in 2 seconds
              observer.unobserve(entry.target);
          }, 500); // Delay before counting up
      }
  });
}

// Create a new IntersectionObserver instance
const observer = new IntersectionObserver(handleIntersection, {
  root: null,
  rootMargin: '0px',
  threshold: 0.5
});

// Observe all elements with the "countup" class
const countupElements = document.querySelectorAll('.countup');
countupElements.forEach(el => {
  observer.observe(el);
});

// Setup count up on click in default-content sibling panels
const defaultButtons = document.querySelectorAll('.default-content .default-button');
defaultButtons.forEach(button => {
  button.addEventListener('click', function () {
      const revealedContent = button.closest('.default-content').nextElementSibling;
      const countUpSpans = revealedContent.querySelectorAll('.countup');
      countUpSpans.forEach(span => {
        setTimeout(() => {
          const target = parseFloat(span.dataset.target);
          countUp(span, target, 2000); // Trigger count up when button is clicked
        }, 500); // Delay before counting up
      });
  });
});




const openButtons = document.querySelectorAll('.default-content .default-button');
openButtons.forEach(button => {
    button.addEventListener('click', function (event) {
        event.preventDefault();
        const panel = button.closest('.panel');
        const parent = panel.closest('.numbers-outer');
        const revealedContent = panel.querySelector('.revealed-content');
        const defaultContent = panel.querySelector('.default-content');

        defaultContent.style.transition = '';
        defaultContent.style.opacity = '0';

        if (window.innerWidth < 768) {
            // Mobile style: Fixed position to cover the entire viewport
            panel.style.position = 'fixed';
            panel.style.top = '0';
            panel.style.left = '0';
            panel.style.width = '100vw';
            panel.style.height = '100vh';
            panel.style.zIndex = 10;
            panel.style.transition = 'all .5s ease-in-out';
            revealedContent.style.opacity = '1';
            revealedContent.style.zIndex = 3;
        } else {
            // Desktop style: Animate position and size to fill parent
            const parentRect = parent.getBoundingClientRect();
            const panelRect = panel.getBoundingClientRect();
            const diffTop = panelRect.top - parentRect.top;
            const diffLeft = panelRect.left - parentRect.left;

            panel.style.position = 'absolute';
            panel.style.top = `${diffTop}px`;
            panel.style.left = `${diffLeft}px`;
            panel.style.width = `${panelRect.width}px`;
            panel.style.height = `${panelRect.height}px`;
            panel.style.zIndex = 7;
            panel.style.transition = 'all .5s ease-in-out';

            // Store original dimensions as data attributes
            panel.dataset.originalTop = `${diffTop}px`;
            panel.dataset.originalLeft = `${diffLeft}px`;
            panel.dataset.originalWidth = `${panelRect.width}px`;
            panel.dataset.originalHeight = `${panelRect.height}px`;

            requestAnimationFrame(() => {
                panel.style.top = '0';
                panel.style.left = '0';
                panel.style.width = `${parentRect.width}px`;
                panel.style.height = `${parentRect.height}px`;
            });
            panel.addEventListener('transitionend', function handler(event) {
              if (event.propertyName === 'width' || event.target === panel) {
                  revealedContent.style.transition = 'opacity 0.3s ease-in-out';
                  revealedContent.style.opacity = '1';
                  revealedContent.style.zIndex = 3;
                  panel.removeEventListener('transitionend', handler);
              }
            });
        }

        // Animate bars
        const bars = document.querySelectorAll('.bar');
        bars.forEach(function(bar) {
            setTimeout(() => {
                const width = bar.getAttribute('data-width');
                bar.style.width = width + '%'; // Add '%' to make it a percentage width
            }, 500);
        });
    });
});

const closeButtons = document.querySelectorAll('.panel .close-button');
closeButtons.forEach(button => {
    button.addEventListener('click', function (event) {
        event.preventDefault();

        const panel = button.closest('.panel');
        const revealedContent = panel.querySelector('.revealed-content');
        const defaultContent = panel.querySelector('.default-content');

        // Immediately set the opacity of the revealed-content to 0
        revealedContent.style.transition = '';
        revealedContent.style.opacity = '0';
        revealedContent.style.zIndex = 1;

        if (window.innerWidth < 768) {
            // For smaller screens, immediately close the overlay without any animation
            panel.style.position = 'relative'; // Reset to original position
            panel.style.top = '';
            panel.style.left = '';
            panel.style.width = '';
            panel.style.height = '';
            panel.style.zIndex = '';

            defaultContent.style.opacity = '1'; // Immediately show default content

            const bars = document.querySelectorAll('.bar');
            bars.forEach(bar => {
                bar.style.width = '0'; // Reset bar widths immediately
            });

            panel.classList.remove('opened');
        } else {
            // For larger screens, animate the close transition
            panel.style.transition = 'all .5s ease-in-out';
            panel.style.position = 'absolute';

            requestAnimationFrame(() => {
                panel.style.top = panel.dataset.originalTop;
                panel.style.left = panel.dataset.originalLeft;
                panel.style.width = panel.dataset.originalWidth;
                panel.style.height = panel.dataset.originalHeight;

                panel.addEventListener('transitionend', function handler(event) {
                    let targetElement = event.target;
                    while (targetElement && targetElement !== document) {
                        if (targetElement === panel) {
                            defaultContent.style.transition = 'opacity 0.3s ease-in-out';
                            defaultContent.style.opacity = '1';

                            // Reset all positioning and size styles after transition
                            panel.style.position = 'relative';
                            panel.style.top = '';
                            panel.style.left = '';
                            panel.style.width = '';
                            panel.style.height = '';
                            panel.style.zIndex = '';

                            panel.classList.remove('opened');
                            panel.removeEventListener('transitionend', handler);

                            // Reset bar chart widths after transition
                            const bars = document.querySelectorAll('.bar');
                            bars.forEach(bar => {
                                bar.style.width = '0';
                            });

                            break;
                        }
                        targetElement = targetElement.parentNode;
                    }
                });
            });
        }
    });
});


})

