jQuery(window).on("load", function ($) {
  console.log("The page has loaded.")
  if (jQuery(window).scrollTop() > 1) {
    jQuery("#main-header").addClass("menu-scrolling")
  } else {
    if (jQuery("#main-header").hasClass("menu-scrolling")) {
      jQuery("#main-header").removeClass("menu-scrolling")
    }
  }
  jQuery("#iframeVideo").on("hidden.bs.modal", function (e) {
    var videoURL = jQuery(this).find("iframe").attr("src")
    jQuery(this).find("iframe").attr("src", "")
    jQuery(this).find("iframe").attr("src", videoURL)
  })
})
