function qs(key) {
  key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
  var match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

$(function () {
  console.log("Grants Filter Loaded");
  var focusAreaParam = qs("focus-area");
  $('#grant-category-select option').addClass('hide-category');
  $('#grant-category-select option.focus-area-' + focusAreaParam).removeClass('hide-category');

  $("#focus-area-select").change(function () {
    var focusAreaVal = $(this).val();
    console.log(focusAreaVal);
    $('#grant-category-select option').addClass('hide-category');
    $('#grant-category-select option.focus-area-' + focusAreaVal).removeClass('hide-category');
  });

  $("#filter-submit").click(function () {
    var focusAreaVal = $("#focus-area-select").val();
    var grantCategoryVal = $("#grant-category-select").val();
    var amountVal = $("#amount-select").val();
    var yearAwardedVal = $("#year-awarded-select").val();
    var queryArray = new Array();
    if (focusAreaVal !== "") {
      queryArray.push("focus-area=" + focusAreaVal);
    }
    if (grantCategoryVal !== "") {
      queryArray.push("grant-category=" + grantCategoryVal);
    }
    if (amountVal !== "") {
      queryArray.push("amount=" + amountVal);
    }
    if (yearAwardedVal !== "") {
      queryArray.push("year-awarded=" + yearAwardedVal);
    }
    var baseQuery = "/grantmaking/grants-awarded/?";
    var queryString = queryArray.join("&");
    //console.log(queryString);
    if (queryString == "") {
      window.location.href = "/grantmaking/grants-awarded/";
    } else {
      window.location.href = baseQuery + queryString;
    }
  });
});