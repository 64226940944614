$(function () {
  $(".tab-navigation > ul > li > h4").on('click', function () {
    if ($(this).parent().children().hasClass("nav-active")) {
      $(this).parent().children().removeClass("nav-active");
    } else {
      $(this).parent().children().addClass("nav-active");
    }
  });
  $(".nav-link").on("click", function () {
    $('.nav-link').each(function () {
      if ($('.nav-link').hasClass('active')) {
        $('.nav-link').removeClass('active');
      }
    });
  });

  let vizElem = $('#vizContainer');
  if(vizElem.length > 0) {
    let vizURL = $('#vizContainer').data('vizurl');
    createViz(vizURL);
  }

  $(".vizLoad").on("click", function () {
    $(this).addClass('active');
    let vizURL = $(this).data('vizurl');
    createViz(vizURL);
  });

});

var viz;

function createViz(vizURL) {
  var vizDiv = document.getElementById("vizContainer"),
  options = {
    width: "100%",
    height: "1000px"
  };
  if($(window).width()<992) {
    options = {
      width: "100%",
      height: "1500px"
    };
  }

  if (viz) { // If a viz object exists, delete it.
    viz.dispose();
  }

  viz = new tableau.Viz(vizDiv, vizURL, options);

  $("#vizContainer").find('iframe').attr("scrolling", "yes");

}