/*******************
 * Script Imports
 *******************/
import _ from "lodash"
import $ from "jquery"
window.$ = window.jQuery = $

import "popper.js"
import "bootstrap"
import "slick-slider"
import "select2"
//import "./../node_modules/bootstrap-table/dist/bootstrap-table.js"
import "./blocks/image-slider/image-slider"
import "./blocks/tabs/tabs"

import "./vendor/vendor"
import "./utility/detect-ie"
import "./utility/is-touch-device"
import "./utility/acf-google-map"
import "./theme/scripts"
import "./theme/on-load.js"
import "./theme/on-scroll.js"
import "./theme/on-resize.js"

import "./blocks/grants-filter/grants-filter"
import "./blocks/card-posts-repeater/card-posts-repeater"
import "./blocks/tab-accordion-repeater/tab-accordion-repeater"
import "./blocks/landing-header/landing-header"
import "./blocks/chart-accordion/chart-accordion"
/*******************
 * Style Imports
 *******************/
import "../scss/main.scss"
